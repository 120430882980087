import { FunctionComponent } from 'react';
import styled from 'styled-components';


type SimpleSpinnerProps = {
  show: boolean
}

export const SimpleSpinner: FunctionComponent<SimpleSpinnerProps> = ({ show }) => {
  return (
    <StyledSimpleSpinner className={`SimpleSpinner ${!show && 'hidden'}`}>
    </StyledSimpleSpinner>
  );
}

const StyledSimpleSpinner = styled.div`
  width: 26px;
  height: 10px;
  margin: 30px auto 0 auto;
  display: flex;
  justify-content: space-between;
  transition: opacity 0.2s ease;

  animation: simpleSpinner 1s ease infinite;

  &:before, &:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #FF0056;
    display: block;
    border-radius: 100%;
  }

  &.hidden {
    opacity: 0;
  }

  @keyframes simpleSpinner {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
