import { FunctionComponent } from "react";
import { Location, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { resetState } from "../store/cartSlice";
import { ProgressBar } from "./ProgressBar";
import { BackButton } from "./BackButton";

const sparrowhubLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/sparrowhub-logo.png`;

type HeaderProps = {

}

export const Header: FunctionComponent<HeaderProps> = () => {
  const domLocation: Location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  // store
  const cart = useAppSelector((state) => state.cart.cart);

  // methods
  const navigateHome = (): void => {
    dispatch(resetState());
    navigate('/');
  }

  const showStore = (): boolean => {
    return [
      '/',
      '/partners'
    ].includes(domLocation.pathname) === false;
  }

  const showProgress = (): boolean => {
    // return domLocation.pathname !== '/confirmation';
    return [
      '/delivery',
      '/checkout'
    ].includes(domLocation.pathname);
  }

  const getProgressContent = () => {
    switch (domLocation.pathname) {
      case '/delivery':
        return {
          heading: cart?.requires_delivery ? 'Select Delivery Method' : 'Enter Your Contact Details',
          stepNumber: 1,
          backButtonTo: '/cart'
        }
      case '/checkout':
        return {
          heading: 'Finalise Payment Details',
          stepNumber: 2,
          backButtonTo: '/delivery'
        }
      default:
        return {
          heading: '',
          stepNumber: 1,
          backButtonTo: ''
        }
    }
  }

  return (
    <>
      <StyledHeader className="Header">
        <div className="Header_inner">
          <img onClick={navigateHome} src={sparrowhubLogo} alt="SparrowHub" title="SparrowHub" />
            <p className="small" id="Header_locationName">
              {(cart && cart.location.name) && <>{cart?.location.name}</> }
            </p>
        </div>
      </StyledHeader>

      {showProgress() &&
        <StyledProgressHeading>
          <div id="Header_backButtonContainer">
            {getProgressContent().backButtonTo !== null &&
              <BackButton to={getProgressContent().backButtonTo} />
            }
          </div>
          <h2 className="bold">{getProgressContent().heading}</h2>
          <ProgressBar current={getProgressContent().stepNumber} length={2} showLabel/>
        </StyledProgressHeading>
      }
    </>
  );
}

const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  height: 90px;
  min-height: 90px;
  padding: 25px 25px 0 25px;
  border-radius: 0 0 2px 2px;
  z-index: 1;
  background: #FAFAFA;
  box-shadow: 0 2px 5px 0 rgba(180, 180, 180, 0.35);

  display: flex;
  align-items: center;
  justify-content: center;

  .Header_inner {
    width: 100%;
    max-width: 640px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 24px;
    overflow: hidden;

    @media screen and (max-width: 650px) {
      padding: 0;
      gap: 20px;
    }

    img {
      width: 140px;
      height: auto;
      cursor: pointer;
    }
  }
`

const StyledProgressHeading = styled.div`
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  margin-top: 26px;
  padding: 0 25px;

  .BackButton {
    margin-left: -3px;
  }

  h2 {
    margin: 10px 0 7px 0;
  }

  #Header_backButtonContainer {
    height: 24px;
  }
`
