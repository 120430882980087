import { FunctionComponent } from "react";
import styled from 'styled-components';

// import { variables } from "../assets/css/variables";

const addIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/icons/add.svg`;

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
}

type ButtonProps = {
  text?: string
  type?: ButtonType
  icon?: boolean
  label?: string
  title?: string
  onClick?: Function
  disabled?: boolean
  loading?: boolean
}

export const Button: FunctionComponent < ButtonProps > = ({
    text,
    type = ButtonType.Primary,
    icon,
    label,
    onClick,
    disabled,
    loading
  }) => {
  const handleClick = () => {
    if (!disabled && !loading && onClick) onClick();
  }

  return (
    <StyledButton
      onClick={handleClick}
      className={`Button Button_${type} ${disabled && 'disabled'} ${loading ? 'Button_loading' : ''}`}
    >
      {label && <label className="bold">{label}</label>}
      <button title={text}>
        {icon && 
          <img src={addIcon} alt="+" title="+" />
        }
        {text}
      </button>
    </StyledButton>
  );
}

const StyledButton = styled.div`
  margin: 14px 0;

  label {
    display: block;
    margin-bottom: 6px;
  }

  button {
    width: 100%;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    padding: 8px 16px;

    &:focus {
      outline: none;
      transform: scale(1.015);
    }
  }

  &.Button_primary {
    button {
      background: black;
      color: white;
      box-shadow: none;
      border: 2px solid black;
    }
  }
  
  &.Button_secondary {
    button {
      background: white;
      color: black;
      box-shadow: none;
      border: 1px solid black;
    }
  }

  &.disabled {
    button {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &.Button_loading {
    button {
      cursor: wait !important;
    }
  }

  /* animation */
  button {
    transition:
      transform 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
    
    &:hover {
      transform: scale(1.015);
    }

    &:active {
      transform: scale(0.985);
    }
  }
`;
