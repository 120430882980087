import { FunctionComponent, ChangeEventHandler, FocusEventHandler } from 'react';
import styled from 'styled-components';

const selectArrow = `${process.env.REACT_APP_ASSET_BASE_PATH}/icons/dropdown.svg`;

export type SelectInputOption = {
  value: string | number | undefined
  label: string
  disabled?: boolean
}

type SelectInputProps = {
  id?: string
  label?: string
  autocomplete?: string
  name?: string
  title?: string
  options: Array<SelectInputOption>
  value?: any
  required?: boolean
  onChange?: ChangeEventHandler
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
}

export const SelectInput: FunctionComponent<SelectInputProps> = ({ id, label, autocomplete, name, title, options, value, required, onChange, onFocus, onBlur }) => {
  return (
    <StyledSelectInput className={`SelectInput ${(required && label) && 'required'}`}>
      <label className="SelectInput_label semibold" htmlFor={id}>{label}</label>
      <select
        className="SelectInput_input"
        id={id}
        autoComplete={autocomplete}
        name={name}
        title={title}
        value={value || ''}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {options.map((option: SelectInputOption, i: number) => {
          const optionLabel = option.label || "any";
          return (
            <option value={option.value} key={`${id}_option-${i}`} disabled={option.disabled}>{optionLabel.charAt(0).toUpperCase() + optionLabel.slice(1)}</option>
          )
        })}
      </select>
      <img className="SelectInput_arrow" src={selectArrow} alt="›" title="›" />
    </StyledSelectInput>
  );
}

const StyledSelectInput = styled.div`
  position: relative;
  margin: 10px 0 25px 0;

  &.required {
    label:after {
      content: "*";
      margin-left: 2px;
      color: black;
    }
  }

  .SelectInput_input {
    width: 100%;
    height: 48px;
    margin: 6px 0 0 0;
    padding: 13px !important;
    border-radius: 6px;
    border: 1px solid #DADADA !important;
    background: white;
    line-height: normal !important;
    
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;

    transition:
      border-color 0.2s ease-in-out;

    &:active, &:focus-visible {
      outline: none;
      padding: 12px !important;
    }

    &::placeholder {
      color: #D4D4D4;
    }

    &:last-of-type {
      /* margin-bottom: 8px; */
    }
  }

  .SelectInput_label {
    margin-left: 4px;
    height: 18px;
  }

  .SelectInput_arrow {
    position: absolute;
    bottom: 20px;
    right: 16px;
    pointer-events: none;
  }

  option {
    color: red;
  }
`