import { Routes, Route, BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ApiProvider } from './context/ApiProvider';

import { Header } from './components/Header';
import { Footer } from './components/Footer';

import { HomePage } from './pages/HomePage';
import { ContactPage } from './pages/ContactPage';
import { FaqPage } from './pages/FaqPage';
import { PartnersPage } from './pages/PartnersPage';
import { CartPage } from './pages/CartPage';
import { DeliveryPage } from './pages/DeliveryPage';
import { CheckoutPage } from './pages/CheckoutPage';
import { ConfirmationPage } from './pages/ConfirmationPage';

function App() {
  return (
    <ApiProvider>
      <StyledApp className={`SparrowHub_Cart v${process.env.REACT_APP_VERSION}`}>
        <BrowserRouter>
          <Header />

            <main className="main">
              <Routes>
                {/* unprotected routes */}
                <Route path="/c/:cartId?" element={<HomePage />} />
                {/* <Route path="/contact" element={<ContactPage />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route path="/partners" element={<PartnersPage />} /> */}

                {/* protected routes */}
                <Route path="/cart" element={<CartPage />} />
                <Route path="/delivery" element={<DeliveryPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/confirmation" element={<ConfirmationPage />} />

                {/* wildcard routes to homepage */}
                <Route path="*" element={<HomePage />} />
              </Routes>
            </main>

          <Footer />
        </BrowserRouter>
      </StyledApp>
    </ApiProvider>
  );
}

const StyledApp = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .main {
    height: 100%;
    flex-grow: 1;
    overflow-x: hidden;
  }
`

export default App;
