import { FunctionComponent } from "react";
// import { useLocation, Location } from "react-router-dom";
import styled from 'styled-components';

// const logoSparrow = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-sparrowscript-sparrow-circle.png`;

type FooterProps = {
}

export const Footer: FunctionComponent<FooterProps> = () => {
  // const domLocation: Location = useLocation();
  
  // const showLogo = (): boolean => {
  //   return domLocation.pathname === '/';
  // }

  return (
    <StyledFooter>
      {/* {showLogo() && 
        <img src={logoSparrow} alt="SparrowHub Logo" title="SparrowHub Logo" />
      } */}

      <p>© {new Date().getFullYear()} - v{process.env.REACT_APP_VERSION}</p>
      <p>Powered by SparrowHub™ - SparrowHub™ is a trademark of Rival Software Pty Ltd</p>

      <p style={{ marginTop: '8px' }}>
        This site is protected by reCAPTCHA and the Google <a className="link" title="Google Privacy Policy" href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a className="link" href="https://policies.google.com/terms" title="Google Terms of Service" target="_blank" rel="noreferrer">Terms of Service</a> apply.
      </p>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  width: 100%;
  min-height: 70px;
  padding: 12px 12px 18px 12px;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* background: #FAFAFA; */

  img {
    width: 30px;
    margin-bottom: 5px;
  }

  p {
    text-align: center;
    font-size: 0.4375rem; // 7px
    color: #929292;
    margin: 0;
  }
`;
