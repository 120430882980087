import { FunctionComponent, useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { Navigate, useNavigate } from "react-router-dom";

import { useApi } from "../context/ApiProvider";
import { AddressCountries, AddressRegions, DeliveryMethod, PublicGetDeliveryMethodsRequest } from "sparrowhub-client-axios";

import { setDelivery, setDeliveryMethods, CustomerDetails, formatItemsArray } from '../store/cartSlice'
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { Button, ButtonType } from "../components/Button";
import { RadioGroup } from "../components/RadioGroup";
import { Alert, AlertIcon, AlertType } from "../components/Alert";
import { AddressInput } from "../components/AddressInput";
import { SimpleSpinner } from "../components/SimpleSpinner";
import { Checkbox } from "../components/Checkbox";

const tickIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/icons/check-white.svg`;
const doordashLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-doordash.png`;
const auspostExpressLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-auspost-express.png`;
const auspostLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-auspost.png`;
const aramexLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-aramex.png`;
const tollLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-toll.png`;

export const deliveryMethodImages = {
  'doordash': doordashLogo,
  'auspost-express': auspostExpressLogo,
  'auspost-standard': auspostLogo,
  'toll': tollLogo,
  'aramex': aramexLogo,
}

export const deliveryMethodLabels = {
  'doordash': 'Same-Day Delivery',
  'auspost-express': 'Express Delivery',
  'auspost-standard': 'Standard Delivery',
  'pickup': 'Click & Collect',
  'toll': 'Express Delivery',
  'aramex': 'Express Delivery',
}

type DeliveryPageProps = {
}

export const DeliveryPage: FunctionComponent<DeliveryPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const api = useApi();

  // store
  const config = useAppSelector((state) => state.cart.config);
  const cart = useAppSelector((state) => state.cart.cart);
  // const scripts = useAppSelector((state) => state.script.scripts);
  const deliveryMethods = useAppSelector((state) => state.cart.deliveryMethods);
  const delivery = useAppSelector((state) => state.cart.delivery);

  // state
  const [showPage, setShowPage] = useState(false);
  const [showDeliveryContent, setShowDeliveryContent] = useState(deliveryMethods.delivery_methods.length !== 0);
  const [showPharmacy, setShowPharmacy] = useState(deliveryMethods.delivery_methods.length !== 0);
  const [showDelivery, setShowDelivery] = useState(deliveryMethods.delivery_methods.length !== 0);
  const [showProceed, setShowProceed] = useState(deliveryMethods.delivery_methods.length !== 0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentStreet, setCurrentStreet] = useState(delivery.street1);
  const [currentCity, setCurrentCity] = useState(delivery.city);
  const [currentPostcode, setCurrentPostcode] = useState(delivery.postcode);
  const [currentState, setCurrentState] = useState(delivery.state_code);

  const googleInitialized = useRef(false);

  // computed
  const addressIsInvalid = (): boolean => {
    const formEl = (document.getElementById('form_delivery-page_delivery-details') as HTMLFormElement);
    const formElInvalid = formEl && !formEl.checkValidity();
    
    const contactInvalid = (
      delivery.first_name === '' ||
      delivery.last_name === '' ||
      delivery.email === '' ||
      delivery.phone === ''
    );
    
    const addressInvalid = !deliveryMethods.noAddressProvided && (
      delivery.street1 === '' ||
      delivery.city === '' ||
      delivery.postcode === '' || delivery.postcode.length !== 4 ||
      delivery.state_code === ''
    );

    return formElInvalid || contactInvalid || addressInvalid;
  }

  const itemsData = (): Array<any> => {
    return []
    // return scripts.map((script: Prescription) => {
    //   return {
    //     sku: script.products[script.selected_product_index].barcode,
    //     name: script.name,
    //     qty: 1,
    //     price: formatPriceFromFloat(getEntitlementPrice(script) || 0),
    //     tax: 0,
    //     is_prescription: true
    //   }
    // })
  }

  // methods
  const next = (): void  => {
    // manually submit GA events
    gtag('event', 'form_submit', {
      'form_id': 'form_delivery-page_shipping-method',
      'form_name': 'Delivery & Collection Options',
      'form_submit_text': 'Proceed to Payment'
    });

    const deliveryMethod = deliveryMethods.delivery_methods.find((option: DeliveryMethod) => option.code === deliveryMethods.delivery_method_code);

    gtag('event', 'add_shipping_info', {
      'currency': 'AUD',
      'items': formatItemsArray(cart!.items),
      ...(deliveryMethod !== undefined ?
        {
          'value': parseFloat(deliveryMethod!.total as unknown as string),
          'shipping_tier': deliveryMethods.delivery_method_code,
        }
      :
        {
          'value': 0,
          'shipping_tier': 'payment_only',
        }
      )
    });

    // handle next
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setShowPage(false);
    setTimeout(() => {
      navigate('/checkout');
    }, 300);
  }

  const handleHideDeliveryContent = (): void => {
    const shouldHide = (
      currentStreet !== delivery.street1 ||
      currentCity !== delivery.city ||
      currentPostcode !== delivery.postcode ||
      currentState !== delivery.state_code
    )

    if (shouldHide) {
      // remove delivery method values from store
      dispatch(setDeliveryMethods({
        delivery_methods: [],
        delivery_method_code: undefined
      }));

      // hide UI
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // });

      setShowPharmacy(false);
      setShowDelivery(false);
      setShowProceed(false);

      setTimeout(() => {
        setShowDeliveryContent(false);
      }, 200);
    }
  }

  const handleShowDelivery = async (): Promise<void> => {
    // manually submit GA event
    gtag('event', 'form_submit', {
      'form_id': 'form_delivery-page_delivery-details',
      'form_name': 'Delivery details',
      'form_submit_text': 'View Delivery & Collection Options'
    });

    // set state
    setErrorMessage('');
    setIsLoading(true);

    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'get_delivery_methods'});
      handleGetDeliveryMethods(token);
    });
  }

  const handleGetDeliveryMethods = async (token: string): Promise<void> => {
    const requestBody: PublicGetDeliveryMethodsRequest = {
      security_token: token,
      is_live_quote: true,
      delivery_method_codes: deliveryMethods.noAddressProvided ? ['pickup'] : ['auspost-standard', 'auspost-express', 'doordash', 'pickup'],
      partner: {
        id: cart!.partner.id,
        location_code: cart!.location.code
      },
      customer: {
        first_name: delivery.first_name,
        last_name: delivery.last_name,
        email: delivery.email,
        phone: delivery.phone
      },
      delivery: {
        first_name: delivery.first_name,
        last_name: delivery.last_name,
        email: delivery.email,
        phone: delivery.phone,
        address: {
          street: JSON.stringify([delivery.street1, delivery.street2]),
          city: delivery.city,
          state_code: deliveryMethods.noAddressProvided ? AddressRegions.Nsw : delivery.state_code as AddressRegions,
          postcode: delivery.postcode,
          country_code: AddressCountries.Au,
        }
      },
      items: itemsData()
    }

    api.publicGetDeliveryMethods(requestBody).then((response) => {
      // handle success
      setIsLoading(false);
      const data = JSON.parse(response.data.data as any);
      
      // set delivery methods in store
      dispatch(setDeliveryMethods({ delivery_methods: data }));

      // set UI state
      setShowDeliveryContent(true);
      setTimeout(() => {
        window.scrollTo({
          top: 9999,
          left: 0,
          behavior: 'smooth'
        });
      }, 100);
      
      setTimeout(() => {
        setShowPharmacy(true);
      }, 200);

      setTimeout(() => {
        setShowDelivery(true);
      }, 400);
      
      setTimeout(() => {
        setShowProceed(true);
      }, 600);
    })
    .catch(error => {
      // handle error
      setIsLoading(false);
      // setErrorMessage(error.response.data);
      setErrorMessage('Unable to retrieve delivery methods.');
      dispatch(setDeliveryMethods({ delivery_methods: [] }));

      console.error(error)
    })
  }

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD'
  });

  const formatPrice = (price: number | string): string => {
    let priceNum = typeof price === 'number'
      ? price
      : parseFloat(price);
    return formatter.format(priceNum);
  }

  useEffect(() => {
    setShowPage(true);
  }, []);

  return (
    <>
      {(!config.has_landed || cart === undefined) ?
        <Navigate to="/" />
      :
        <>
          <StyledDeliveryPage className={`pageTransition ${!showPage && 'hidden'}`}>
            <section className="DeliveryPage_form fullWidth_parent">
              <div className="fullWidth_child">
                <form id="form_delivery-page_delivery-details">
                  <AddressInput heading={cart.requires_delivery ? 'Delivery Details' : 'Contact Details'} address={delivery} onSetAddress={(payload: Partial<CustomerDetails>) => dispatch(setDelivery(payload))} noAddress={deliveryMethods.noAddressProvided} />
                  <Checkbox id="noAddress" title="Don't provide address" selected={deliveryMethods.noAddressProvided} onChange={() => dispatch(setDeliveryMethods({ noAddressProvided: !deliveryMethods.noAddressProvided }))}>
                    <>Don't provide address</>
                  </Checkbox>
                </form>

                {!cart.requires_delivery ?
                  <Button text="Proceed to Payment" type={ButtonType.Primary} onClick={next} disabled={addressIsInvalid()} />
                :
                  <>
                    {!showDeliveryContent ?
                      <>
                        <Button text="View Delivery and Collection Options" type={ButtonType.Primary} disabled={addressIsInvalid() || isLoading} loading={isLoading} onClick={handleShowDelivery} />
                        <SimpleSpinner show={isLoading} />

                        {errorMessage &&
                          <div style={{ marginTop: '20px' }}>
                            <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                              <p>{errorMessage}<br/>Please try again.</p>
                            </Alert>
                          </div>
                        }
                      </>
                    :
                      <>
                        <div className={`DeliveryPage_options elementTransition ${!showDelivery && 'hidden'}`}>
                          <h3>Delivery and Collection Options</h3>
                          <form name="Delivery & Collection Options" id="form_delivery-page_shipping-method"></form>
                          <RadioGroup value={cart.courier_type_code} label="You are eligible for the following shipping and collection methods:" title="Shipping and collection methods">
                            {deliveryMethods.delivery_methods.map((option: DeliveryMethod, i: number) => {
                              return (
                                <div key={option.code} id={option.code} onClick={() => dispatch(setDeliveryMethods({ delivery_method_code: option.code }))} className={`RadioButton ${deliveryMethods.delivery_method_code === option.code && 'selected'}`} title={option.name}>
                                  <input type="radio" name="shipping" id={option.code} value={option.code} style={{ backgroundImage: `url(${tickIcon})` }} />
                                  <div className="RadioButton_flex">
                                    <div className="RadioButton_label">
                                      <p>{deliveryMethodLabels[option.code as keyof typeof deliveryMethodImages]}</p>
                                      <img src={option.code === 'pickup' ? `${process.env.REACT_APP_ASSET_BASE_PATH}/partners/${cart!.partner_id}/logo-full.png` : deliveryMethodImages[option.code as keyof typeof deliveryMethodImages]} alt={option.name} title={option.name} />
                                    </div>
                                    <p>{option.delivery_estimate}</p>
                                  </div>
                                  <p className="bold">{formatPrice(option.total)}</p>
                                </div>
                              )
                            })}
                          </RadioGroup>
                        </div>

                        <div className={`elementTransition ${!showProceed && 'hidden'}`}>
                          <Button text="Proceed to Payment" type={ButtonType.Primary} onClick={next} disabled={addressIsInvalid() ||  deliveryMethods.delivery_method_code === undefined} />
                        </div>
                      </>
                    }
                  </>
                }
              </div>
            </section>
          </StyledDeliveryPage>
        </>
      }
    </>
  );
}

const StyledDeliveryPage = styled.div`
  .DeliveryPage_form {
    padding-top: 12px;

    h3 {
      font-size: 1.125rem; // 18px
    }

    .DeliveryPage_options {
      margin-top: 45px;
    }
  }

  .RadioButton {
    display: grid;
    grid-template-columns: 40px 1fr 50px;
    align-items: center;
    width: 100%;
    height: 78px;

    .RadioButton_flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;

      .RadioButton_label {
        display: flex;
        align-items: center;
        height: 18px;
        
        p {
          font-weight: 500 !important;
          margin-right: 8px;
        }

        img {
          max-width: 65px;
          height: auto;
          max-height: 64px;
          object-fit: contain;
          display: inline-block;
        }
      }
    }
  }

  #form_delivery-page_delivery-details {
    .Checkbox {
      margin: -10px 10px 30px 10px !important;
    }
  }
`;

