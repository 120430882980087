import { FunctionComponent } from "react";
import styled from 'styled-components';


type BasketProps = {
  heading?: string
  children: any
}

export const Basket: FunctionComponent<BasketProps> = ({ heading, children }) => {
  return (
    <StyledBasket className="Basket">
      <div id="basket">
        {heading && <h3>{heading}</h3>}
        {children}
      </div>
    </StyledBasket>
  );
}

const StyledBasket = styled.div`
  #basket {
    padding-bottom: 5px;
    padding: 30px 30px 20px 30px;
    border-radius: 6px;
    background: #F8F8F8;

    * {
      color: black;
    }

    h3 {
      font-size: 1.125rem; // 18px
      margin-top: 0;
    }

    .Basket_section {
      &:not(:last-of-type) {
        border-bottom: 1px solid #E2E2E2;
      }

      .Basket_row {
        p {
          /* display: flex;
          justify-content: space-between; */
          display: grid;
          grid-template-columns: 1fr 90px;
          font-size: 0.875rem; // 14px

          span:last-child {
            text-align: right;
          }
        }

        &.qty {
          p {
            display: grid;
            grid-template-columns: 30px 1fr 90px;
            
            span:nth-child(1) {
              margin-right: 10px;
            }
          }
        }
      }

      .Basket_item {
        margin-bottom: 20px;

        .Basket_itemHeader {
          display: flex;
          justify-content: space-between;
        }
        .Basket_itemDetails {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 5px 20px;

          p {
            font-size: 0.5rem; // 8px
            display: grid;
            margin: 0;
            grid-template-columns: 90px 1fr;

            &:nth-child(2n) {
              grid-template-columns: 70px 1fr;
            }
          }
        }
      }
    }
  }
`;
