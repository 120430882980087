import { FunctionComponent, useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Navigate } from "react-router";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { CartWithItems, DeliveryMethod } from "sparrowhub-client-axios";
import { deliveryMethodImages } from "./DeliveryPage";
import { formatAddress, resetState } from "../store/cartSlice";

import { Button, ButtonType } from "../components/Button";

const confirmationGraphic = `${process.env.REACT_APP_ASSET_BASE_PATH}/graphics/cart_confirmation_graphic.svg`;
const landingGraphic = `${process.env.REACT_APP_ASSET_BASE_PATH}/graphics/cart_landing_graphic.svg`;

type ConfirmationPageProps = {
}

export const ConfirmationPage: FunctionComponent<ConfirmationPageProps> = () => {
  const dispatch = useAppDispatch();
  
  // store
  const config = useAppSelector((state) => state.cart.config);
  
  // store
  const cart = useAppSelector((state) => state.cart.cart);
  const deliveryMethods = useAppSelector((state) => state.cart.deliveryMethods);

  // state
  const [showPage, setShowPage] = useState(false);
  
  // use refs to preserve necessary values after store is cleared
  const landedRef = useRef<boolean | undefined>(undefined);
  const cartRef = useRef<CartWithItems | undefined>(undefined);
  const nameRef = useRef<string | undefined>(undefined);
  const deliveryRef = useRef<DeliveryMethod | undefined>(undefined);
  
  // computed methods to handle transition between store and refs
  const localCart = (): CartWithItems | undefined => {
    return cart || cartRef.current;
  }
  const localName = (): string | undefined => {
    return cart?.delivery_firstname || cart?.billing_firstname || nameRef.current;
  }
  const localDelivery = (): DeliveryMethod | undefined => {
    return deliveryMethods.delivery_methods.length > 0
      ? deliveryMethods.delivery_methods.find((option: DeliveryMethod) => option.code === deliveryMethods.delivery_method_code)
      : deliveryRef.current;
  }

  // populate refs on page load
  useEffect(() => {
    setShowPage(true);

    if (landedRef.current === undefined) landedRef.current = config.has_landed;
    if (cartRef.current === undefined) cartRef.current = cart;
    if (nameRef.current === undefined) nameRef.current = cart?.delivery_firstname || cart?.billing_firstname || '';
    if (deliveryRef.current === undefined) deliveryRef.current = deliveryMethods.delivery_methods.find((option: DeliveryMethod) => option.code === deliveryMethods.delivery_method_code);

    dispatch(resetState())
  }, []);

  return (
    <>
      {((!config.has_landed && !landedRef.current) || (cart === undefined && cartRef.current === undefined)) ?
        <Navigate to="/" />
      :
        <>
          <StyledConfirmationPage className={`pageTransition ${!showPage && 'hidden'}`}>
          {createPortal(
            <>{localCart()!.location.name}</>,
            document.getElementById('Header_locationName')!
          )}
          
            <section className="ConfirmationPage_head fullWidth_parent">
              <div className="fullWidth_child">
                <h1>Thank you for your order{localName() ? `, ${localName()}` : '!'}</h1>
                <img src={localCart()?.requires_delivery ? confirmationGraphic : landingGraphic} alt="Pharmacist graphic" title="Pharmacist graphic" />
                <p className="bold xlarge">Your order is being {localCart()!.requires_delivery ? 'packed' : 'processed'}</p>
                <p className="semibold">
                  {localCart()!.requires_delivery ?
                    <>{`You will receive an email update once your order ${localDelivery()!.code === 'pickup' ? 'is ready for pickup' : 'has been dispatched'}`}</>
                  :
                    <>You will receive order updates via email</>
                  }
                </p>
              </div>
            </section>

            {localCart()!.requires_delivery &&  
              <section className="ConfirmationPage_delivery fullWidth_parent" style={{ background: '#F3F9FB' }}>
                <div className="fullWidth_child">
                  <p className="semibold medium">{localDelivery()!.code === 'pickup' ? 'Pick up your order from' : 'Your order will be delivered by'}</p>
                  <img src={localDelivery()!.code === 'pickup' ? `${process.env.REACT_APP_ASSET_BASE_PATH}/partners/${localCart()!.partner_id}/logo-full.png` : deliveryMethodImages[localDelivery()!.code as keyof typeof deliveryMethodImages]} alt={localDelivery()!.name} title={localDelivery()!.name} />
                  <p className="medium">{localDelivery()!.code === 'pickup' ? formatAddress(localCart()!.location.address) : localDelivery()!.delivery_estimate}</p>
                </div>
              </section>
            }
            
            <section className="ConfirmationPage_contact fullWidth_parent">
              <div className="fullWidth_child">
                <p className="semibold">Have a question?</p>
                <a href={`https://www.google.com/maps/place/?q=place_id:${localCart()!.location.google_place_id}`} target="_blank" rel="noopener noreferrer" title={`${localCart()!.location.name} on Google Maps`}>
                  <Button text="Contact Your Pharmacy" type={ButtonType.Secondary} />
                </a>
              </div>
            </section>
          </StyledConfirmationPage>
        </>
      }
    </>
  );
}

const StyledConfirmationPage = styled.div`
  .ConfirmationPage_head {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      margin: 20px 0 0 0;
    }

    p {
      margin-bottom: 0;

      &:last-child {
        margin-top: 9px;
        padding: 0 40px;
      }
    }
  }

  .ConfirmationPage_delivery {
    .fullWidth_child {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: -10px 0;

      img {
        width: 150px;
        height: 30px;
        object-fit: contain;
        margin: 5px 0;
      }

      p {
        margin: 0;
      }
    }
  }
  
  .ConfirmationPage_contact {
    .fullWidth_child {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin: 0;
      }

      a {
        width: 100%;
      }
    }
  }
`;
