import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { store } from './store'
import { Provider } from 'react-redux'

import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe, StripeElements } from '@stripe/stripe-js';

import App from './App';
import './assets/css/style.scss';

declare global {
  var grecaptcha: any;
  var gtag: any;
  interface Window {
    stripe: Stripe | null;
    stripeElements: StripeElements | null;
  }
}

if (process.env.REACT_APP_STRIPE_KEY !== undefined) {
  // loadStripe(process.env.REACT_APP_STRIPE_KEY).then(result => {
  //   window.stripe = result;
  // })
} else {
  console.warn('No Stripe key found, unable to init Stripe.')
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
