import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import styled from 'styled-components';

import { useApi } from "../context/ApiProvider";
import { DeliveryMethod, CartItem } from "sparrowhub-client-axios";

import { getBasketSubtotal, setBilling, formatPhoneString, formatPriceFromFloat, formatAddress, setDelivery, CustomerDetails, formatPriceToString, getBasketGST, formatItemsArray } from "../store/cartSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { Basket } from "../components/Basket";
import { PaymentInput } from "../components/PaymentInput";

const storeIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/icons/click-and-collect.svg`;
const phoneIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/icons/phone.svg`;

const loaderSteps: Array<string> = [
  'Confirming payment...',
  'Processing your order...',
  'Almost there...',
  'Done!'
]

type CheckoutPageProps = {
}

export const CheckoutPage: FunctionComponent<CheckoutPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const api = useApi();
  
  // store
  const config = useAppSelector((state) => state.cart.config);
  const cart = useAppSelector((state) => state.cart.cart);
  const deliveryMethods = useAppSelector((state) => state.cart.deliveryMethods);
  const delivery = useAppSelector((state) => state.cart.delivery);
  const billing = useAppSelector((state) => state.cart.billing);
  
  // state
  const [showPage, setShowPage] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState(deliveryMethods.delivery_methods.find((option: DeliveryMethod) => option.code === deliveryMethods.delivery_method_code));

  // computed
  const basketTotal = (): number => {
    let total = getBasketSubtotal(cart!.items);
    if (cart!.requires_delivery) {
      total += parseFloat(deliveryMethod!.total as unknown as string);
    }
    return total;
  }

  // methods
  const next = (): void => {
    setShowPage(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setTimeout(() => {
      navigate('/confirmation');
    }, 300);
  }

  // init page
  useEffect(() => {
    setTimeout(() => {
      setShowPage(true);
      window.scrollTo(0, 0);

      if (config.has_landed) {
        gtag('event', 'begin_checkout', {
          'currency': 'AUD',
          'value': getBasketSubtotal(cart!.items),
          'items': formatItemsArray(cart!.items)
        });
      }
    }, 10);
  }, []);

  return (
    <>
      {!config.has_landed ?
        <Navigate to="/" />
      :
        <>
          {(
            cart === undefined ||
            cart.items === undefined ||
            (cart.requires_delivery && (deliveryMethods.delivery_methods.length === 0 || deliveryMethod === undefined))
          ) ?
            <Navigate to="/delivery" />
          :
            <>
              <StyledCheckoutPage className={`pageTransition ${!showPage && 'hidden'}`}>
                <section className="CheckoutPage_basket fullWidth_parent">
                  <div className="fullWidth_child">
                    <Basket heading="Your Cart">
                      <div className="Basket_section">
                        {cart.items.map((item: CartItem, i: number) => {
                          return (
                            <div className="Basket_row qty" key={i}>
                              <p>
                                <span className="bold">{item.qty}</span>
                                <span>{item.name}</span>
                                <span className="semibold">{item.price !== undefined ? formatPriceToString(formatPriceFromFloat(parseFloat(item.price))) : ''}</span>
                              </p>
                            </div>
                          )
                        })}
                      </div>
                      <div className="Basket_section">
                        <div className="Basket_row">
                          <p><span>Sub-Total</span><span className="semibold">{formatPriceToString(formatPriceFromFloat(getBasketSubtotal(cart.items)))}</span></p>
                        </div>
                        {cart.requires_delivery &&
                          <div className="Basket_row">
                            <p><span>Delivery</span><span className="semibold">{formatPriceToString(formatPriceFromFloat(parseFloat(deliveryMethod!.total as unknown as string)))}</span></p>
                          </div>
                        }
                        <div className="Basket_row">
                          <p><span>GST</span><span className="semibold">{formatPriceToString(getBasketGST(cart.items, deliveryMethod))}</span></p>
                        </div>
                      </div>
                      <div className="Basket_section Basket_total">
                        <div className="Basket_row">
                          <p><span className="bold">Total</span><span className="bold">{formatPriceToString(formatPriceFromFloat(basketTotal()))}</span></p>
                        </div>
                      </div>
                    </Basket>
                  </div>
                </section>
                
                <section className="CheckoutPage_pharmacy fullWidth_parent" style={{ background: '#F3F9FB' }}>
                  <div className="fullWidth_child">
                    <p className="bold header">{cart.requires_delivery ? 'Your order will be fulfilled by' : 'You are ordering from'} {cart.location.name}</p>
                    <div className="detail">
                      <img src={storeIcon} alt="Store address icon" title="Store address icon" />
                      <p>{formatAddress(cart.location.address)}</p>
                    </div>
                    <div className="detail">
                      <img src={phoneIcon} alt="Phone icon" title="Phone icon" />
                      <p>{formatPhoneString(cart.location.contact_phone)}</p>
                    </div>
                  </div>
                </section>
                
                <section className="CheckoutPage_form fullWidth_parent">
                  <div className="fullWidth_child">
                    <PaymentInput
                      heading="Payment Information"
                      cart={cart!}
                      orderItems={cart!.items}
                      deliveryMethod={deliveryMethod!}
                      deliveryAddress={delivery}
                      billingAddress={billing}
                      onSetDeliveryAddress={(payload: Partial<CustomerDetails>) => dispatch(setDelivery(payload))}
                      onSetBillingAddress={(payload: Partial<CustomerDetails>) => dispatch(setBilling(payload))}
                      onSuccess={next}
                      noAddress={deliveryMethods.noAddressProvided}
                    />
                  </div>
                </section>
              </StyledCheckoutPage>
            </>
          }
        </>
      }
    </>
  );
}

const StyledCheckoutPage = styled.div`
  .CheckoutPage_basket {
    padding-top: 12px;
  }

  .CheckoutPage_pharmacy {
    .fullWidth_child {
      position: relative;
      z-index: 1;

      .pharmacy-logo {
        height: 33px;
        width: auto;
      }

      .header {
        margin-top: 0;
      }

      .detail {
        display: grid;
        grid-template-columns: 21px 1fr;
        gap: 4px;

        img {
          width: 16px;
          height: 16px;
        }

        p {
          margin: 1px 0 0 0;
          font-weight: 500;
        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      p {
        font-size: 0.75rem; // 12px
        font-weight: 300;
        
        &.header {
          font-size: 0.875rem; // 14px
          display: block;
        }
      }

      .bold:not(.header) {
        margin-top: 15px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }

      .courier-logo {
        width: 90px;
        margin-left: 8px;
      }
    }
  }

  .RadioButton {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    width: 100%;

    img {
      width: auto;
      height: 22px;
      object-fit: contain;
      padding-right: 20px;
    }

    &:last-child {
      margin-bottom: 20px;
    }
  }

  .Payment_row {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 10px;

    :nth-child(2) {
      label {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .Checkbox {
    margin: 15px 0 0 0;
  }

  .Checkout_billingAddress {
    margin-top: 20px;
    margin-bottom: -16px;

    .Checkout_addressInput {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 16px;

      .InputField {
        margin-bottom: 0;
      }
    }
  }

  .Checkout_modalParent {
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0) !important;

    &.hidden {
      pointer-events: none;

      * {
        pointer-events: none;
      }
    }

    .Modal_close {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    iframe {
      border: none;
      max-width: 100%;
    }
  }
`;
