import { FunctionComponent } from "react";
import styled from 'styled-components';

type ProgressBarProps = {
  current: number
  length: number
  showLabel?: boolean
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({ current, length, showLabel }) => {
  const innerWidth = (): string => {
    return `${(current / length) * 100}%`;
  }

  return (
    <StyledProgressBar className="ProgressBar" role="progressbar" aria-valuemin={0} aria-valuemax={length} aria-valuenow={current}>
      <div className="ProgressBar_outer">
        <div className="ProgressBar_inner" style={{ width: innerWidth() }}></div>
      </div>
      {showLabel &&
        <p className="ProgressBar_label light">Step {current} of {length}</p>
      }
    </StyledProgressBar>
  );
}

const StyledProgressBar = styled.div`
  position: relative;
  width: 100%;
  
  .ProgressBar_outer {
    background: #F0F0F0;
    width: 100%;
    height: 7px;
    border-radius: 7px;

    .ProgressBar_inner {
      background: linear-gradient(to right, #FFA4C3, #FF0056);
      height: 100%;
      border-radius: 7px;
      transition: width 0.2s ease-in-out;
    }
  }

  .ProgressBar_label {
    margin: 6px 0;
    text-align: right;
    font-size: 0.625rem; // 10px
    font-style: italic;
    color: black;
  }
`;
