import { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";

import { useApi } from "../context/ApiProvider";
import { CartItem, CartLookupRequest, CartWithItems, PolicyFeature } from "sparrowhub-client-axios";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setCart, setConfig } from "../store/cartSlice";
import { Params, useNavigate, useParams } from "react-router-dom";
import { Button, ButtonType } from "../components/Button";

const landingGraphic = `${process.env.REACT_APP_ASSET_BASE_PATH}/graphics/cart_landing_graphic.svg`;
const doordashLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-doordash.png`;
const auspostExpressLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-auspost-express.png`;
const auspostLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-auspost.png`;
const aramexLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-aramex.png`;

type HomePageProps = {
}

export const HomePage: FunctionComponent<HomePageProps> = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // analytics variables
  const idCart = 'G-MF5X7N67JT';
  const idCartStrongPro = 'G-0QFERLZZGE';
  enum CampaignSource {
    SparrowHub = 'sparrowhub',
    StrongPro = 'strongpro'
  }
  enum CampaignMedium {
    SMS = 'sms',
  }
  enum CampaignNamePrefix {
    Scripts = 'scripts',
    Cart = 'cart'
  }

  // params
  const { cartId }: Readonly<Params<string>> = useParams();

  // store
  const cart = useAppSelector((state) => state.cart.cart);

  // state
  const [showPage, setShowPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // methods
  const handleCartLookup = async (cartReference: string): Promise<void> => {
    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const recaptchaToken = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'cart_lookup'});

      const requestBody: CartLookupRequest = {
        security_token: recaptchaToken
      }

      api.cartLookup(cartReference, requestBody).then((response) => {
        const cart: CartWithItems = response.data.data;

        // Treat non-StrongPro carts as invalid if items are missing qty or price
        if (cart.feature === PolicyFeature.Carts && cart.items.some((item: CartItem) => {
          return item.price === undefined || item.price === null || item.qty === undefined || item.qty === null
        })) {
          throw new Error('Some Cart items have missing price or qty data.')
        } else {
          dispatch(setCart(cart));
          handleAnalytics(cart).then(() => {
            if (cart.feature === PolicyFeature.Scripts) {
              handleRedirect(cart);
            } else {
              navigate('/cart');
            }
          })
        }
      })
      .catch(error => {
        console.error(error);

        handleAnalytics(undefined);

        setShowPage(true);
        setErrorMessage('This link is invalid');
        // TODO: differentiate between invalid and expired states
        // setErrorMessage('This link has expired');
      })
    });
  }

  const handleAnalytics = async (cart: CartWithItems | undefined): Promise<void> => {
    return new Promise((resolve,reject) => {
      if (cart === undefined) {
        // analytics for error state
        gtag('config', idCart, {
          ...(process.env.NODE_ENV === 'development' && {
            'debug_mode': 'true'
          })
        });
      } else {
        // analytics for success state
        const isScriptsTarget = cart.feature === PolicyFeature.Scripts;
        const options = {
          'linker': {
            'domains': ['scripts.sparrowhub.com.au']
          },
          'campaign_source': isScriptsTarget ? CampaignSource.StrongPro : CampaignSource.SparrowHub,
          'campaign_medium': CampaignMedium.SMS,
          'campaign_name': isScriptsTarget ? `${CampaignNamePrefix.Scripts}_${cart.reference}` : `${CampaignNamePrefix.Cart}_${cart.reference}`,
          'campaign_id': cart.reference,
          // use GA debug_mode in dev
          ...(process.env.NODE_ENV === 'development' && {
            'debug_mode': 'true'
          })
        };

        // init default Cart analytics
        gtag('config', idCart, options);

        // init StrongPro analytics if required
        if (isScriptsTarget) {
          gtag('config', idCartStrongPro, options);
        }
      }

      // track Cart lookup event
      gtag('event', 'cart_lookup', {
        'success': cart !== undefined,
      });

      // resolve
      resolve();
    })
  }

  const promiseDelay = (t: number, val: any) => {
    return new Promise(resolve => {
      setTimeout(resolve.bind(null, val), t);
    });
  }

  const promiseRaceAll = (promises: Array<Promise<any>>, timeoutTime: number, timeoutVal: any) => {
    return Promise.all(promises.map(p => {
      return Promise.race([p, promiseDelay(timeoutTime, timeoutVal)])
    }));
  }

  const handleRedirect = (cart: CartWithItems): void => {
    // construct redirect URL
    const domain = window.location.origin.includes('-stag') ? 'https://scripts-stag.sparrowhub.com.au' : 'https://scripts.sparrowhub.com.au';
    const partnerParam = encodeURIComponent(btoa(cart.partner_id.toString()));
    const cartParam = encodeURIComponent(cart.reference);

    // retrieve client/session params
    const pClient = new Promise(r => gtag('get', idCart, 'client_id', r));
    const pSession = new Promise(r => gtag('get', idCart, 'session_id', r));
    const pSRClient = new Promise(r => gtag('get', idCartStrongPro, 'client_id', r));
    const pSRSession = new Promise(r => gtag('get', idCartStrongPro, 'session_id', r));

    // await all params, or proceed without after timeout
    const timeout = 5000;
    promiseRaceAll([pClient, pSession, pSRClient, pSRSession], timeout, null).then((values) => {
      const [cid, sid, srcid, srsid] = values;
      
      let url = `${domain}/?partner=${partnerParam}&cart=${cartParam}`;
      if (cid !== null) url += `&cid=${cid}`;
      if (sid !== null) url += `&sid=${sid}`;
      if (srcid !== null) url += `&srcid=${srcid}`;
      if (srsid !== null) url += `&srsid=${srsid}`;
      
      window.location.replace(url);
    });
  }

  // set has_landed
  useEffect(() => {
    dispatch(setConfig({ has_landed: true }));
  }, [ dispatch ]);

  // handle url param
  useEffect(() => {
    if (cartId !== undefined) {
      handleCartLookup(cartId);
    } else {
      setShowPage(true);
    }
  }, [ cartId ])

  return (
    <StyledHomePage className={`pageTransition ${!showPage && 'hidden'}`}>
      <section className="HomePage_head fullWidth_parent">
          <div className="fullWidth_child">
            {errorMessage === '' ?
              <h1>Purchasing from your pharmacy has never been easier</h1>
            :
              <h1>{errorMessage}</h1>
            }
            
            <img src={landingGraphic} alt="Pharmacist graphic" title="Pharmacist graphic" />
            
            {errorMessage === '' ?
              <p className="bold xlarge">Contact your pharmacy now to request a delivery.</p>
            :
              <p className="bold xlarge">Please contact your pharmacy to receive a new cart link.</p>
            }
            <a href="https://catandclaudias.com.au/" target="_blank" rel="noreferrer" title="Cat and Claudia's Community Pharmacy">
              <Button text="Request a Delivery" type={ButtonType.Primary} />
            </a>
          </div>
      </section>

      <section className="HomePage_faqs fullWidth_parent" style={{ background: '#F3F9FB' }}>
        <div className="fullWidth_child">
          <h2>Frequently Asked Questions</h2>
          <div className="question">
            <h3 className="bold">How do share carts work?</h3>
            <p>The share cart allows your pharmacist to send you a pre-populated cart with your recommended items. Once you receive the cart via email or SMS, you can review the contents, complete the secure online payment, and choose a delivery option.</p>
          </div>
          <div className="question">
            <h3 className="bold">What delivery options are available?</h3>
            <p>You can choose from multiple delivery options, including Australia Post Express, Australia Post Standard, or DoorDash delivery if you're within eligible suburbs surrounding the pharmacy.<br /><br />If you prefer, you can also select the Click & Collect option to pick up your order in person.</p>
          </div>
          <div className="question">
            <h3 className="bold">Is my payment information secure?</h3>
            <p>Yes, all transactions processed through the share cart are highly secure. We have partnered with Tyro Health to protect your payment details and ensure a safe and reliable checkout experience.</p>
          </div>
        </div>
      </section>

      <section className="HomePage_couriers fullWidth_parent">
        <div className="fullWidth_child">
          <p className="bold large">We've partnered with trusted couriers for fast and reliable delivery</p>
          <div className="logos">
            <img id="doordash" src={doordashLogo} alt="DoorDash" title="DoorDash"/>
            <img id="auspost" src={auspostLogo} alt="Australia Post" title="Australia Post"/>
            <img id="auspostExpress" src={auspostExpressLogo} alt="Australia Post Express" title="Australia Post Express"/>
            <img id="aramex" src={aramexLogo} alt="Aramex" title="Aramex"/>
          </div>
        </div>
      </section>
    </StyledHomePage>
  );
}

const StyledHomePage = styled.div`
  a {
    display: block;
  }

  .HomePage_head {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      margin: 20px 0;
    }
  }

  .HomePage_couriers {
    .fullWidth_child {
      p {
        text-align: center;
      }

      .logos {
        margin-top: 30px;
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px 25px;
        gap: 15px 25px;

        img {
          height: auto;

          &#doordash {
            width: 139px;
          }
          &#auspostExpress {
            width: 120px;
            /* grid-column: span 2; */
            /* margin: 0 auto; */
          }
          &#auspost {
            width: 123px;
          }
          &#aramex {
            width: 107px;
            margin-top: -11px;
          }

          &:nth-child(2n - 1) {
            margin-left: auto;
          }
        }
      }
    }
  }

  .HomePage_faqs {
    h2 {
      padding: 0 30px;
      text-align: center;
    }

    .question {
      background: white;
      border-radius: 20px;
      padding-bottom: 22px;
      margin-bottom: 15px;

      h3 {
        font-size: 0.875rem; // 14px
        background: #F8F8F8;
        margin: 0;
        border-radius: 20px 20px 0 0;
        padding: 18px;
      }

      p {
        font-size: 0.875rem; // 14px
        padding: 0 18px;
        margin-bottom: 0;
      }

      ol {
        margin-top: 1rem !important;
        margin-bottom: 0;
        padding-right: 18px;
        
        li {
          font-size: 0.875rem; // 14px
          margin-bottom: 0.5rem; // 8px

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
