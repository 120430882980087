import { FunctionComponent } from "react";
import styled from 'styled-components';

type RadioGroupProps = {
  children: any
  label?: string
  value?: any
  id?: string
  title?: string
  onChange?: Function
  required?: boolean
  disabled?: boolean
}

export const RadioGroup: FunctionComponent<RadioGroupProps> = ({ children, label, value, id, title, onChange, required, disabled }) => {
  const handleClick = (): void => {
    if (onChange) onChange(value);
  }

  return (
    <StyledRadioGroup className={`RadioGroup ${required && 'required'} ${disabled && 'disabled'}`} onClick={handleClick} title={title}>
      {label && <label className="RadioGroup_label" htmlFor={id}>{label}</label>}
      {children}
    </StyledRadioGroup>
  );
}



const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 11px 0 35px 0;

  .RadioGroup_label {
    font-size: 0.875rem; // 14px
    margin-bottom: 5px;
    font-weight: 400;
  }

  .RadioButton {
    display: grid;
    align-items: center;
    width: 100%;
    min-height: 48px;
    box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.35);
    border-radius: 6px;
    padding: 11px;
    cursor: pointer;
    background: white;
    border: 1px solid white;

    transition:
      border-color 0.2s ease-in-out;

    p {
      font-size: 0.875rem; // 14px
      font-weight: 300;
      margin: 0;
      color: black;
    }
  }

  input {
    appearance: none;
    background: #F0F0F0;
    border-radius: 100%;
    outline: none;
    border: none !important;

    width: 20px;
    height: 20px;
    margin: 1px;
    pointer-events: none;
    margin-right: 11px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 0px;

    transition:
      background-color 0.2s ease-in-out;
  }

  .selected {
    outline: none;
    border-color: black;
    
    input {
      outline: none;
      background-color: black;
      background-size: 12px;
    }

    p {
      font-weight: 500;
      color: black;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  label {
    font-size: 0.625rem; // 10px
  }

  &.required {
    label:after {
      content: "*";
      margin-left: 2px;
      color: black;
    }
  }
`;
