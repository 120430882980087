import { ChangeEvent, FunctionComponent, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { AddressCountries, AddressRegions } from 'sparrowhub-client-axios';
import { CustomerDetails } from '../store/cartSlice';

import { SelectInput, SelectInputOption } from './SelectInput';
import { InputField } from './InputField';

const stateOptions: Array<SelectInputOption> = [
  {
    value: undefined,
    label: 'Select one:',
    disabled: true
  },
  ...Object.values(AddressRegions).map((state) => {
    return {
      value: state,
      label: state
    }
  })
];

const countryOptions: Array<SelectInputOption> = Object.values(AddressCountries).map((country) => {
  return {
    value: country,
    label: country
  }
});

type AddressInputProps = {
  heading?: string
  address: Partial<CustomerDetails>
  onSetAddress: Function
  noAddress?: boolean
}

export const AddressInput: FunctionComponent<AddressInputProps> = ({
  heading,
  address,
  onSetAddress,
  noAddress = false
}) => {
  const googleInitialized = useRef(false);
  
  const addressIsNotEmpty = (): boolean => {
    return (
      address.street1 !== '' ||
      address.street2 !== '' ||
      address.state_code !== '' ||
      address.postcode !== ''
    )
  }

  const getFieldsFromGoogleAddressComponents = (displayName: string, addressComponents: Array<google.maps.places.AddressComponent>): Partial<CustomerDetails> => {
    let subpremise = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('subpremise'));
    let street_number = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('street_number'));
    let route = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('route'));
    let locality = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('locality'));
    let state = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('administrative_area_level_1'));
    let postal_code = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('postal_code'));
  
    const fields: Partial<CustomerDetails> = {
      street1: street_number && route ? `${street_number.shortText} ${route.shortText}` : '',
      street2: subpremise ? subpremise.shortText || '' : '',
      city: locality ? locality.shortText || '' : '',
      state_code: state ? state.shortText as AddressRegions || '' : '',
      postcode: postal_code ? postal_code.shortText || '' : '',
    }

    return fields;
  }

  // init Google Places Autocomplete widget
  useEffect(() => {
    const initAutocomplete = async () => {
      const container = document.getElementById('Delivery_addressWidgetContainer');

      //@ts-ignore
      await google.maps.importLibrary("places") as google.maps.PlacesLibrary;
      //@ts-ignore
      const placeAutocomplete = new google.maps.places.PlaceAutocompleteElement({
        componentRestrictions: {country: ['au']},
      });
      
      //@ts-ignore
      if (container) {
        container.appendChild(placeAutocomplete);
      } else {
        console.error('Unable to find container element for Autocomplete widget.')
      }

      // Add the gmp-placeselect listener, and display the results.
      //@ts-ignore
      placeAutocomplete.addEventListener('gmp-placeselect', async ({ place }) => {
        await place.fetchFields({ fields: ['displayName', 'addressComponents'] });
        const json = place.toJSON();
        const fields = getFieldsFromGoogleAddressComponents(json.displayName, json.addressComponents);
        onSetAddress(fields);
      });
    }
    if (!googleInitialized.current) {
      googleInitialized.current = true;
      initAutocomplete()
        .catch(console.log);
    }
  }, []);
  
  // clear data if no address provided
  useEffect(() => {
    if (noAddress) {
      onSetAddress({
        street1: '',
        street2: '',
        city: '',
        postcode: '',
        state_code: '',
      })
    }
  }, [ noAddress ]);
  
  return (
    <StyledAddressInput className="AddressInput">
      {heading && <h3>{heading}</h3>}

      {/* name inputs */}
      <div className="columns">
        <InputField type="text" id="firstName" label="First Name" value={address.first_name} required onChange={(e: ChangeEvent) => onSetAddress({ first_name: (e.target as HTMLInputElement).value })} />
        <InputField type="text" id="lastName" label="Last Name" value={address.last_name} required onChange={(e: ChangeEvent) => onSetAddress({ last_name: (e.target as HTMLInputElement).value })} />
      </div>

      {/* contact inputs */}
      <InputField type="email" id="customerEmail" label="Email" value={address.email} required onChange={(e: ChangeEvent) => onSetAddress({ email: (e.target as HTMLInputElement).value })} />
      <InputField type="tel" id="customerPhone" label="Mobile Number" value={address.phone} required onChange={(e: ChangeEvent) => onSetAddress({ phone: (e.target as HTMLInputElement).value })} />
      
      <div className={`${noAddress ? 'disabled' : ''}`}>
        {/* google address lookup  */}
        <div style={{ margin: '20px 0 30px 0' }}>
          <label className="semibold" htmlFor="Delivery_addressWidgetContainer" style={{ display: 'block', marginBottom: '6px' }}>Address Lookup</label>
          <div id="Delivery_addressWidgetContainer"></div>
        </div>

        {/* manual address inputs */}
        {addressIsNotEmpty() &&
          <>
            <InputField type="text" id="street1" label="Street Address" value={address.street1} required onChange={(e: ChangeEvent) => onSetAddress({ street1: (e.target as HTMLInputElement).value })} />
            <InputField type="text" id="street2" value={address.street2} onChange={(e: ChangeEvent) => onSetAddress({ street2: (e.target as HTMLInputElement).value })} />
            <InputField type="text" id="suburb" label="Suburb" value={address.city} required onChange={(e: ChangeEvent) => onSetAddress({ city: (e.target as HTMLInputElement).value })} />
            
            <div className="columns">
              <InputField type="text" id="postcode" label="Postcode" value={address.postcode} regex={/^\d{0,4}$/} required onChange={(e: ChangeEvent) => onSetAddress({ postcode: (e.target as HTMLInputElement).value })} />
              <SelectInput id="state" label="State" options={stateOptions} value={address.state_code} required onChange={(e: ChangeEvent) => onSetAddress({ state_code: (e.target as HTMLInputElement).value as AddressRegions })} />
              {/* <SelectInput id="country" label="Country" options={countryOptions} value={address.country} required onChange={(e: ChangeEvent) => onSetAddress({ country: (e.target as HTMLInputElement).value as AddressCountries })} /> */}
            </div>
          </>
        }
      </div>

    </StyledAddressInput>
  );
}

const StyledAddressInput = styled.div`
  h3 {
    font-size: 1.125rem !important; // 18px
    margin: 0;
  }

  .InputField label {
    display: inline-block;
    /* margin-top: 20px; */
  }

  #street2 {
    margin-top: -10px;
  }

  .disabled {
    * {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`