import axios from 'axios';
import { createContext, useContext, FunctionComponent } from "react";
import { SparrowCartApiInterface, SparrowCartApiFactory } from "sparrowhub-client-axios";

// init context
const ApiContext = createContext<SparrowCartApiInterface | null>(null);

type ApiProviderProps = {
  children: any,
}

export const ApiProvider: FunctionComponent<ApiProviderProps> = ({ children }) => {
  const basePath = `${process.env.REACT_APP_BACKEND_API_HOST}/api/v1`;
  const axiosInstance = axios.create({
    baseURL: basePath,
    withCredentials: false
  });

  const api: SparrowCartApiInterface = SparrowCartApiFactory(undefined, basePath, axiosInstance);

  return (
    <ApiContext.Provider value={ api }>
      { children }
    </ApiContext.Provider>
  );
}

// Api hook
export function useApi(): SparrowCartApiInterface {
  const apiContext = useContext(ApiContext);

  if (!apiContext) {
    throw new Error("ApiProvider has returned context as null");
  }

  return apiContext;
}
