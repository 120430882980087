import { FunctionComponent, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Navigate } from "react-router";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { formatAddress, formatPhoneString, formatPriceFromFloat, formatPriceToString } from "../store/cartSlice";

import { Button, ButtonType } from "../components/Button";
import { Basket } from "../components/Basket";
import { CartItem } from "sparrowhub-client-axios";

const landingGraphic = `${process.env.REACT_APP_ASSET_BASE_PATH}/graphics/cart_landing_graphic.svg`;
const tyroHealthLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-tyro-health.png`;
const storeIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/icons/click-and-collect.svg`;
const phoneIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/icons/phone.svg`;

type CartPageProps = {
}

export const CartPage: FunctionComponent<CartPageProps> = () => {
  const dispatch = useAppDispatch();

  // store
  const config = useAppSelector((state) => state.cart.config);
  const cart = useAppSelector((state) => state.cart.cart);
  
  // state
  const [showPage, setShowPage] = useState(false);

  // methods
  // const updateOrderItemQty = (sku: string, qty: number) => {
  //   let mutatedItems = JSON.parse(JSON.stringify(cart.order_items));
  //   let itemToUpdate = mutatedItems.find((i: OrderItem) => i.sku === sku);
  //   itemToUpdate.qty_ordered = qty;
  //   dispatch(setCart({ order_items: mutatedItems }));
  // }
  
  // const removeOrderItem = (sku: string) => {
  //   let mutatedItems = JSON.parse(JSON.stringify(cart.order_items));
  //   let indexToRemove = mutatedItems.findIndex((i: OrderItem) => i.sku === sku);
  //   mutatedItems.splice(indexToRemove, 1);   
  //   dispatch(setCart({ order_items: mutatedItems }));
  // }

  // const setOrderItems = (items: Array<OrderItem>) => {
  //   dispatch(setCart({ order_items: items }));
  // }
  
  // const resetOrderItems = () => {
  //   dispatch(setCart({ order_items: cart.quote_items }));
  // }

  useEffect(() => {
    setShowPage(true);

    // init order items
    // resetOrderItems();
  }, []);

  return (
    <>
      {!config.has_landed || cart === undefined ?
        <Navigate to="/" />
      :
        <>
          <StyledCartPage className={`pageTransition ${!showPage && 'hidden'}`}>
            <section className="CartPage_head fullWidth_parent">
              <div className="fullWidth_child">
                <h1>Hi there, {cart.delivery_firstname}</h1>
                <img src={landingGraphic} alt="Pharmacist graphic" title="Pharmacist graphic" />
                <p className="bold xlarge">{cart.location.name}<br />has shared a cart with you</p>
              </div>
            </section>
            
            <section className="CartPage_basket fullWidth_parent">
              <div className="fullWidth_child">
                <Basket heading="Your Cart">
                  <div className="Basket_section">
                    {cart.items.map((item: CartItem, i: number) => {
                      return (
                        <div className="Basket_row qty" key={i}>
                          <p>
                            <span className="bold">{item.qty}</span>
                            <span>{item.name}</span>
                            <span className="semibold">{item.price !== undefined ? formatPriceToString(formatPriceFromFloat(parseFloat(item.price))) : ''}</span>
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </Basket>

                <div className="CartPage_container checkout">
                  <NavLink to="/delivery">
                    <Button text="Continue to Checkout" />
                  </NavLink>
                  <img src={tyroHealthLogo} alt="Secured by Tyro Health" title="Secured by Tyro Health" />
                </div>

                <div className="CartPage_container contact">
                  <p className="semibold">Something not right?</p>
                  <a href={`https://www.google.com/maps/place/?q=place_id:${cart!.location.google_place_id}`} target="_blank" rel="noopener noreferrer" title={`${cart!.location.name} on Google Maps`}>
                    <Button text="Contact Your Pharmacy" type={ButtonType.Secondary} />
                  </a>
                </div>
              </div>
            </section>

            <section className="CartPage_pharmacy fullWidth_parent" style={{ background: '#F3F9FB' }}>
              <div className="fullWidth_child">
                <img className="pharmacy-logo" src={`${process.env.REACT_APP_ASSET_BASE_PATH}/partners/${cart!.partner_id}/logo-full.png`} alt={`${cart.location.name} logo`} title={`${cart.location.name} logo`} />
                <p className="bold header">{cart.requires_delivery ? 'Your order will be fulfilled by' : 'You are ordering from'} {cart.location.name}</p>
                <div className="detail">
                  <img src={storeIcon} alt="Store address icon" title="Store address icon" />
                  <p>{formatAddress(cart.location.address)}</p>
                </div>
                <div className="detail">
                  <img src={phoneIcon} alt="Phone icon" title="Phone icon" />
                  <p>{formatPhoneString(cart.location.contact_phone)}</p>
                </div>
              </div>
            </section>
          </StyledCartPage>
        </>
      }
    </>
  );
}

const StyledCartPage = styled.div`
  .CartPage_head {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      margin: 20px 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .CartPage_basket {
    padding-top: 0;

    .fullWidth_child {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .Basket {
        margin-bottom: 30px;
        width: 100%;
      }

      .CartPage_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
          width: 100%;
        }

        p {
          margin-bottom: 0;
        }

        &.checkout {
          margin-bottom: 50px;
        }
      }
  
      img {
        width: 153px;
        height: auto;
      }
    }
  }

  .CartPage_pharmacy {
    .fullWidth_child {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .pharmacy-logo {
        max-width: 200px;
        max-height: 47px;
        object-fit: contain;
      }

      .header {
        margin-top: 20px;
        padding: 0 30px;
      }

      .detail {
        margin-top: 10px;
        
        img {
          width: 16px;
          height: 16px;
        }

        p {
          font-size: 0.75rem; // 12px
          margin-top: 4px;
          font-weight: 500;
        }
      }
    }
  }
`;
