import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Address, CartItem, CartWithItems, DeliveryMethod, OrderItem } from 'sparrowhub-client-axios'

// ----------------------------------------------------------- TEMP DUMMY DATA

const dummyQuoteItems: Array<OrderItem> = [
  {
    id: 1,
    created_at: '',
    order_id: 0,
    // quote_id: 'q-01',
    sku: 'sku01',
    name: 'Product Name 01',
    total: "10.99",
    // price: 10.99,
    qty_ordered: 3,
    partner_id: 3,
    reference: 'reference',
    is_prescription: false,
    requires_contact: false,
  },
  {
    id: 2,
    created_at: '',
    order_id: 0,
    // quote_id: 'q-01',
    sku: 'sku02',
    name: 'Product Name 02',
    total: "15.99",
    // price: 15.99,
    qty_ordered: 1,
    partner_id: 3,
    reference: 'reference',
    is_prescription: false,
    requires_contact: false,
  }
]

// ----------------------------------------------------------- TYPES

export interface Config {
  has_landed: boolean
}

export interface DeliveryMethods {
  delivery_methods: Array<DeliveryMethod>
  delivery_method_code: string | undefined
  noAddressProvided: boolean
}

export interface CustomerDetails {
  first_name: string
  last_name: string
  email: string
  phone: string
  street1: string
  street2: string
  city: string
  state_code: string
  postcode: string
}

export interface Payment {
  selected_payment: string
  card_number: string
  expiry_month: string
  expiry_year: string
  ccv: string
  card_name: string
}

export interface CartState {
  config: Config
  cart: CartWithItems | undefined
  deliveryMethods: DeliveryMethods
  delivery: CustomerDetails
  billing: CustomerDetails
  payment: Payment
}

export interface IIndexable<T = any> {
  [key: string]: T
}

// ----------------------------------------------------------- INITIAL STATE

const initialState: CartState = {
  config: {
    has_landed: false,
  },
  cart: undefined,
  deliveryMethods: {
    delivery_methods: [],
    delivery_method_code: undefined,
    noAddressProvided: false
  },
  delivery: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    street1: '',
    street2: '',
    city: '',
    state_code: '',
    postcode: ''
  },
  billing: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    street1: '',
    street2: '',
    city: '',
    state_code: '',
    postcode: ''
  },
  payment: {
    selected_payment: '',
    card_number: '',
    expiry_month: '',
    expiry_year: '',
    ccv: '',
    card_name: ''
  }
}

// ----------------------------------------------------------- UTIL METHODS

export const getBasketSubtotal = (items: Array<CartItem>): number => {
  let subtotal = 0;
  items.forEach((item: CartItem) => {
    if (item.price !== undefined && item.qty !== undefined) {
      subtotal += parseFloat(item.price) * item.qty;
    }
  });
  return subtotal;
}

export const getBasketGST = (items: Array<any>, deliveryMethod: DeliveryMethod | undefined): number => {
  let subtotal = 0;
  // add item tax
  items.forEach((item: CartItem) => {
    if (item.tax !== undefined && item.qty !== undefined) {
      subtotal += parseFloat(item.tax) * item.qty;
    }
  })
  // add delivery tax if required
  if (deliveryMethod) {
    subtotal += parseFloat(deliveryMethod.tax as unknown as string);
  }
  return subtotal;
}

export const getFieldsFromGoogleAddressComponents = (displayName: string, addressComponents: Array<google.maps.places.AddressComponent>): Partial<CustomerDetails> => {
  let subpremise = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('subpremise'));
  let street_number = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('street_number'));
  let route = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('route'));
  let locality = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('locality'));
  let state = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('administrative_area_level_1'));
  let postal_code = addressComponents.find((c: google.maps.places.AddressComponent) => c.types.includes('postal_code'));

  const addressFields = {
    street1: street_number && route ? `${street_number.shortText} ${route.shortText}` : '',
    street2: subpremise ? subpremise.shortText || '' : '',
    city: locality ? locality.shortText || '' : '',
    state_code: state ? state.shortText || '' : '',
    postcode: postal_code ? postal_code.shortText || '' : '',
  }

  return addressFields;
}

export const formatItemsArray = (items: Array<CartItem>): any => {
  return items.map(item => formatItemObject(item));
}

export const formatItemObject = (item: CartItem): any => {
  return {
    'item_id': item.sku,
    'item_name': item.name,
    'price': item.price,
    'quantity': item.qty,
    'gtin': item.gtin
  }
}

export const formatAddress = (address: Address): string => {
  return `${JSON.parse(address.street!).join(' ')}, ${address.city} ${address.region_code} ${address.postcode}`;
}

export const formatPhoneString = (phone: String): String => {
  if (phone.startsWith('+614')) {
    // mobile
    return `0${phone.substring(3, 6)} ${phone.substring(6, 9)} ${phone.substring(9, 12)}`
  } else if (phone.startsWith('+612')) {
    // landline
    return `(02) ${phone.substring(4, 8)} ${phone.substring(8, 12)}`
  } else {
    // fallback
    return phone;
  }
}

export const formatPriceFromInt = (price: number): number => {
  return price / 100;
}

export const formatPriceFromFloat = (price: number): number => {
  return Math.round(price * 100) / 100;
}

export const formatPriceToInt = (price: number): number => {
  const str = price.toString()
  const int = str.split('.')
  return Number(price.toFixed(2).replace('.', '').padEnd(int.length === 1 ? 3 : 4, '0'))
}

const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD'
});
export const formatPriceToString = (price: number | string): string => {
  let priceNum = typeof price === 'number'
    ? price
    : parseFloat(price);
  return formatter.format(priceNum);
}

export const clamp = (num: number, min: number | undefined, max: number | undefined): number => {
  return Math.min(Math.max(num, min || num), max || num);
}

// ----------------------------------------------------------- SLICE

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      for (const [key, value] of Object.entries(state)) {
        (state as IIndexable)[key] = (initialState as IIndexable)[key];
      }
    },
    setConfig: (state, action: PayloadAction<Partial<Config>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.config as IIndexable)[key] = value;
      }
    },
    setCart: (state, action: PayloadAction<CartWithItems>) => {
      state.cart = action.payload;
    },
    setCartPartial: (state, action: PayloadAction<Partial<CartWithItems>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.cart as IIndexable)[key] = value;
      }
    },
    setDeliveryMethods: (state, action: PayloadAction<Partial<DeliveryMethods>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.deliveryMethods as IIndexable)[key] = value;
      }
    },
    setDelivery: (state, action: PayloadAction<Partial<CustomerDetails>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.delivery as IIndexable)[key] = value;
      }
    },
    setBilling: (state, action: PayloadAction<Partial<CustomerDetails>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.billing as IIndexable)[key] = value;
      }
    },
    setPayment: (state, action: PayloadAction<Partial<Payment>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.payment as IIndexable)[key] = value;
      }
    },
  },
})

export const {
  resetState,
  setConfig,
  setCart,
  setCartPartial,
  setDeliveryMethods,
  setDelivery,
  setBilling,
  setPayment
} = cartSlice.actions

export default cartSlice.reducer